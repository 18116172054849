<template>
  <div class="space-y-2 pb-4">
    <span class="text-xl md:mt-4" v-text="title" />
    <div
      class="text-neutral-dark-1 dark:text-neutral-light-1 text-sm"
      v-text="selection"
    />
  </div>
</template>

<script setup lang="ts">
import type { Cinema } from '#gql/default'

interface Props {
  cinema: Cinema
  dateLabel?: string
  showGroupLabel?: string
}

const props = defineProps<Props>()

defineOptions({
  name: 'ExpressBookingMobileStepHeader',
})

const { t } = useI18n()
const { currentStep } = useExpressBooking()

const title = computed(() => {
  if (currentStep.value === EXPRESS_BOOKING_STEP.MOVIE) {
    return t('title.movieSelection')
  } else if (currentStep.value === EXPRESS_BOOKING_STEP.DATE) {
    return t('title.dateSelection')
  } else if (currentStep.value === EXPRESS_BOOKING_STEP.SHOW) {
    return t('title.showSelection')
  }
})

const selection = computed(() =>
  [props.cinema.name, props.showGroupLabel, props.dateLabel]
    .filter(Boolean)
    .join(' | ')
)
</script>

<i18n>
de:
  title:
    movieSelection: 'Film auswählen'
    dateSelection: 'Datum auswählen'
    showSelection: 'Vorstellung auswählen'
es:
  title:
    movieSelection: 'Seleccionar película'
    dateSelection: 'Seleccionar fecha'
    showSelection: 'Seleccionar función'
</i18n>
